import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react';

const ButtonControls = forwardRef(({ position, minX, maxX, minY, maxY }, ref) => {

  useImperativeHandle(ref, () => ({
    releaseAllButtons: () => {
      [37, 38, 39, 40].forEach((keyCode) => handleMouseUp(keyCode));
    },
  }));

  const handleGlobalMouseUp = (event) => {
    const keyCode = event.keyCode;
    if ([37, 38, 39, 40].includes(keyCode)) {
      handleMouseUp(keyCode);
    }
  };

  useEffect(() => {
    window.addEventListener('mouseup', handleGlobalMouseUp);
    return () => {
      window.removeEventListener('mouseup', handleGlobalMouseUp);
    };
  }, []);

  const keyFromKeyCode = (keyCode) => {
    switch (keyCode) {
      case 37:
        return 'ArrowLeft';
      case 38:
        return 'ArrowUp';
      case 39:
        return 'ArrowRight';
      case 40:
        return 'ArrowDown';
      default:
        return null;
    }
  };

  const intervalIdRef = useRef();

  const handleMouseDown = (keyCode) => {
    const key = keyFromKeyCode(keyCode);
    if (!intervalIdRef.current) {
      intervalIdRef.current = setInterval(() => {
        window.dispatchEvent(new KeyboardEvent('keydown', { key }));
      }, 10);
    }
  
    const onMouseUpHandler = (event) => {
      handleMouseUp(keyCode);
      window.removeEventListener('mouseup', onMouseUpHandler);
    };
  
    window.addEventListener('mouseup', onMouseUpHandler);
  };

  const handleMouseUp = (keyCode) => {
    clearInterval(intervalIdRef.current); 
    intervalIdRef.current = null; 
    const key = keyFromKeyCode(keyCode);
    if (key) {
      window.dispatchEvent(new KeyboardEvent('keyup', { key }));
    }
  };

  const handleTouchEnd = (keyCode) => {
    clearInterval(intervalIdRef.current); 
    intervalIdRef.current = null; 
    const key = keyFromKeyCode(keyCode);
    if (key) {
      window.dispatchEvent(new KeyboardEvent('keyup', { key }));
    }
  };

  const handleTouchStart = (keyCode) => {
    const key = keyFromKeyCode(keyCode);
    if (!intervalIdRef.current) {
      intervalIdRef.current = setInterval(() => {
        window.dispatchEvent(new KeyboardEvent('keydown', { key }));
      }, 10);
    }
  };


  return (
    <div className="button-container">
      {(
        <>
          <button
            className={`button button-up ${position.y === maxY ? 'opacity-0' : ''}`}
            onMouseDown={() => handleMouseDown(38)}
            onMouseUp={() => handleMouseUp(38)}
            onTouchStart={() => handleMouseDown(38)}
            onTouchEnd={() => handleMouseUp(38)}
          >
            ↑
          </button>
          <button
            className={`button button-down ${position.y === minY ? 'opacity-0' : ''}`}
            onMouseDown={() => handleMouseDown(40)}
            onMouseUp={() => handleMouseUp(40)}
            onTouchStart={() => handleMouseDown(40)}
            onTouchEnd={() => handleMouseUp(40)}
          >
            ↓
          </button>
        </>
      )}
      {(
        <>
          <button
            className={`button button-left ${position.x === maxX ? 'opacity-0' : ''}`}
            onMouseDown={() => handleMouseDown(37)}
            onMouseUp={() => handleMouseUp(37)}
            onTouchStart={() => handleMouseDown(37)}
            onTouchEnd={() => handleMouseUp(37)}
          >
            ←
          </button>
          <button
            className={`button button-right ${position.x === minX ? 'opacity-0' : ''}`}
            onMouseDown={() => handleMouseDown(39)}
            onMouseUp={() => handleMouseUp(39)}
            onTouchStart={() => handleMouseDown(39)}
            onTouchEnd={() => handleMouseUp(39)}
          >
            →
          </button>
        </>
      )}
    </div>
  );
});

export default ButtonControls;