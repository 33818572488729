import React from 'react';
import loadingGif from '../assets/logo_anim.gif';

const Loading = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <img src={loadingGif} alt="Loading" style={{height: '15vh'}}/>
    </div>
  );
};

export default Loading;