import React from 'react';

const GameOptions = ({
    bookTitle,
    bookReview,
    lang 
}) => {
  const handleRestart = () => {
    window.location.reload();
  };

  const handleShare = () => {
    const url = window.location.href;
    const text = (lang === "en") ? "🎲 Check out this neat game set in an old publishing house and get a fictional review on your book idea.📚 #LaichterHouse In my case it was: "  + bookReview  : "🎲 Vstupte do starého nakladatelství navrženém Janem Kotěrou a získejte fiktivní recenzi na svou budoucí knihu.📚 V mém případě to bylo: "  + bookReview;
    const shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`;
    window.open(shareUrl, '_blank');
  };

  const handleInstagram = () => {
    const instagramUrl = 'https://www.instagram.com/laichter.house/';
    window.open(instagramUrl, '_blank');
  };

  const handleWeb = () => {
    const webUrl = 'https://laichter.house/';
    window.open(webUrl, '_self');
  };

  return (
    <div className="gameOptions">
      <button id="bookConfirm" className="gameOptionsItem" onClick={handleRestart}>{lang === "en" ? "Start over" : "Začít znovu"}</button>
      <button id="bookConfirm" className="gameOptionsItem" onClick={handleShare}>{lang === "en" ? "Share review on Twitter" : "Sdílet na Twitteru"}</button>
      <button id="bookConfirm" className="gameOptionsItem" onClick={handleWeb}>{lang === "en" ? "Back to main website" : "Zpět na web"}</button>
    </div>
  );
};

export default GameOptions;
