import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import Agent from './components/Agent';
import { ReactComponent as Level_1 } from './assets/svg/Kotera_100-01.svg';
import MovementMechanics from './components/MovementMechanics.js';
import ButtonControls from './components/ButtonControls.js';
import TextInCenter from './components/TextEvent.js';
import LevelLogic from './components/LevelLogic';
import Book from './components/Book';
import queryString from 'query-string';
import Loading from './components/Loading.js'; // Import the Loading component

function App() {
  const [position, setPosition] = useState({ x: -250, y: -150 });
  const [maxX, setMaxX] = useState(-250);
  const [minX, setMinX] = useState(-500);
  const [maxY, setMaxY] = useState(-150);
  const [minY, setMinY] = useState(-150);
  const [level, setLevel] = useState(1);
  const [textEventData, setTextEventData] = useState("")
  const [background, setBackground] = useState(
    <Level_1 id="level" height="1080px" className="centered" />
  )
  const [direction, setDirection] = useState('idle');
  const [mission, setMission] = useState(false)
  const [mission2, setMission2] = useState(false)

  const [bookTitle, setBookTitle] = useState('')
  const [bookReview, setBookReview] = useState('');
  const buttonControlsRef = useRef();

  const parsedQueryString = queryString.parse(window.location.search);
  const lang = parsedQueryString.lang || 'default_lang';

  const [showGameOptions, setShowGameOptions] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadingTimer = setTimeout(() => {
      setIsLoading(false);
    }, 5000); // Display the loading animation for 4 seconds
  
    return () => clearTimeout(loadingTimer);
  }, []);
  
  return (
    <div className="App">
      {isLoading ? (
        <Loading /> // Render the Loading component
      ) : (
        <>
          <ButtonControls 
            ref={buttonControlsRef}
            position={position} 
            minX={minX} 
            maxX={maxX} 
            minY={minY} 
            maxY={maxY} 
          />
          <TextInCenter 
            text={textEventData}
            level={level}
            mission={mission}
            bookTitle={bookTitle}
            setBookTitle={setBookTitle}
            bookReview={bookReview}
            setBookReview={setBookReview}
            lang={lang}
            showGameOptions={showGameOptions}
          />
          <div className="center-container">
            <Book />
            <Agent direction={direction} maxY={maxY} position={position} />
            {background}
          </div>
          <MovementMechanics 
            setDirection={setDirection}
            position={position} 
            setPosition={setPosition} 
            background={background}
            maxX={maxX}
            maxY={maxY}
            minX={minX}
            minY={minY}
          />
          <LevelLogic
            ref={buttonControlsRef}
            position={position}
            setPosition={setPosition} 
            level={level}
            textEventData={textEventData}
            background={background}
            maxX={maxX}
            maxY={maxY}
            minX={minX}
            minY={minY}
            mission={mission}
            mission2={mission2}
            setTextEventData={setTextEventData}
            setBackground={setBackground}
            setMaxX={setMaxX}
            setMaxY={setMaxY}
            setMinX={setMinX}
            setMinY={setMinY}
            setMission={setMission}
            setMission2={setMission2}
            setLevel={setLevel}
            bookReview={bookReview}
            lang={lang}
            showGameOptions={showGameOptions}
            setShowGameOptions={setShowGameOptions}
          />
        </>
      )}
    </div>
  );
}

export default App;