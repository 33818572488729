import React from 'react';
import usePreloadImages from './usePreloadImages';

function Agent({ direction }) {
  const images = [
    '/img/standard_walk_left.gif',
    '/img/walk_up.gif',
    '/img/standard_walk_left.gif',
    '/img/standard_walk_right.gif',
    '/img/jump.gif',
    '/img/idle.gif',
  ];

  usePreloadImages(images);

  const getBackgroundImage = () => {
    switch (direction) {
      case 40:
        return images[0];
      case 38:
        return images[1];
      case 37:
        return images[2];
      case 39:
        return images[3];
      case 1:
        return images[4];
      case 0:
        return images[5];
      default:
        return images[5];
    }
  };


  return (
    <div
      className="agent"
      style={{
        backgroundImage: `url(${getBackgroundImage()})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor: 'transparent',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '200px',
        height: '175px',
        zIndex: 4,
      }}
    />
  );
}

export default Agent;