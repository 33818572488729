import React, { useState, useEffect, useRef } from 'react';

import { ReactComponent as Level_1 } from '../assets/svg/Kotera_100-01.svg';
import { ReactComponent as Level_2 } from '../assets/svg/Kotera_100-02.svg';
import { ReactComponent as Level_3 } from '../assets/svg/Kotera_100-03.svg';
import { ReactComponent as Level_4 } from '../assets/svg/Kotera_100-04.svg';
import { ReactComponent as Level_5 } from '../assets/svg/Kotera_100-05.svg';
import { ReactComponent as Level_6 } from '../assets/svg/Kotera_100-06.svg';

import GameOptions from './GameOptions';

const LevelLogic = ({ 
    position, 
    setPosition, 
    level, 
    textEventData, 
    background, 
    maxX, 
    maxY, 
    minX, 
    minY, 
    mission, 
    mission2, 
    setTextEventData, 
    setBackground, 
    setMaxX, 
    setMaxY, 
    setMinX, 
    setMinY, 
    setMission, 
    setMission2, 
    setLevel,
    buttonControlsRef,
    bookReview,
    lang,
    showGameOptions,
    setShowGameOptions,
}) => {
    const [hasGoneUp, setHasGoneUp] = useState(false);
    


    const resetButtons = () => {
        // Check if buttonControlsRef is defined before calling releaseAllButtons
        if (buttonControlsRef && buttonControlsRef.current) {
          buttonControlsRef.current.releaseAllButtons();
        }
      };
  
    useEffect(() => {
    // console.log(lang)

    if (level === 1) {

        // L1 INTRO 
        if (position.x === -250) {
            setTextEventData(
                lang === "en"
                ? "Press → on the screen or on the keyboard."
                : "Zmáčkněte → na obrazovce nebo na klávesnici."
            )
            const agents = document.getElementsByClassName('agent');
            if (agents && agents.length > 0) {
                agents[0].style.opacity = '100%';
            }   
        }; 

        // L1 GOAL 
        if (position.x === -420) {
            setTextEventData(
                lang === "en"
                ? "Welcome, honored visitor! I see you already know how to use the controls. My name is Jan Kotěra, and I'll be happy to guide you through the house I designed for Jan Laichter. Please, come in!"
                : "Velectěný návštěvníku! Vidím, ovládání používat již umíte. Jmenuji se Jan Kotěra a rád Vás provedu mnou navrženým domem Jana Laichtera. Račte vstoupit!"
            )
            // hide a css object with id #eventCircle
            const eventCircle = document.getElementById('eventCircle');
            if (eventCircle) { eventCircle.style.display = 'none'; }
            // open door 
            const door = document.getElementById('affordances');
            if (door) { door.style.visibility = 'visible'; }
            setMission(true)
        };
        
        // L1 DOOR 
        if (mission && position.x === -500){
            setMission(false)
            const door = document.getElementById('affordances');
            if (door) { door.style.visibility = ''; }
            setLevel(2);
            setBackground(
                <Level_2 id="level" height="1080px" className="centered" />
            );
            setMaxX(-440);
            setMaxY(0);
            setMinX(-590);
            setMinY(-150);
            setTextEventData(
                lang === "en"
                    ? "Please start by cleaning your shoes before entering."
                    : "Začněte prosím tím, že si při vstupu očistíte svou obuv.")
            resetButtons();
        }
    
    } else if (level === 2) {      
        // L2 GOAL 
        if (position.y === -150 && position.x === -440) {
            setTextEventData(
                lang === "en"
                    ? "Isn't it great to have a handle to hold on to?"
                    : "Není-liž skvělé mít i uchýtku, kde se můžete podržet?"
            )
            // hide a css object with id #eventCircle
            const eventCircle = document.getElementById('eventCircle');
            if (eventCircle) { eventCircle.style.display = 'none'; }
            // open door 
            const door = document.getElementById('affordances');
            if (door) { door.style.visibility = 'visible'; }
        setMission(true)
        };
        // L2 DOOR 
        if (mission && position.y === 0 && position.x <= -480 && position.x >= -550){
            setMission(false)
            const door = document.getElementById('affordances');
            if (door) { door.style.visibility = ''; }
            setLevel(3);
            setBackground(
                <Level_3 id="level" height="1080px" className="centered" />
            );
            setMaxX(-280);
            setMaxY(0);
            setMinX(-620);
            setMinY(0);
            setTextEventData(
                lang === "en"
                    ? "Mr Laichter, the publisher, says that every good book starts with a good idea. So let's turn on the light bulb."
                    : "Pan nakladatel Laichter říká, že každá dobrá kniha začíná dobrým nápadem. Tak to chce, aby se nám tu nejdříve rozsvítilo.")
            resetButtons();
        }
    } else if (level === 3) {
        // BRANCHING PATH 
        if ( !hasGoneUp && position.y === 0 && position.x >= -540 && position.x <= -520 && maxY === 0) {
            setMaxY(140);
        } 
        if (!hasGoneUp && position.y === 0 && (position.x < -540 || position.x > -520) && maxY === 140) {
            setMaxY(0);
        } 
        if (!hasGoneUp && position.y > 0 && position.x >= -540 && position.x <= -520 && maxY === 140) {
            setHasGoneUp(true);
            setMaxX(-520);
            setMinX(-540);
        }
        if (hasGoneUp && position.y === 0 && position.x >= -540 && position.x <= -520 && maxY === 140) {
            setHasGoneUp(false);
            setMaxX(-280);
            setMinX(-620);
        }
        // L3 GOAL 
        if (position.y === 130 && position.x >= -540 && position.x <= -520) {
            setTextEventData(
                lang === "en"
                    ? "It seems like you have an idea for your book! Now you only need to decide on the details."
                    : "Zdá se, že máte nápad na knihu! Teď už se jen pustit do psaní."
            )
            // hide a css object with id #eventCircle
            const eventCircle = document.getElementById('eventCircle');
            if (eventCircle) { eventCircle.style.display = 'none'; }
            // open door 
            const door = document.getElementById('affordances');
            if (door) { door.style.visibility = 'visible'; }
            const light = document.getElementById('ligt');
            if (light) { light.style.visibility = 'visible'; }
            const lightCircle = document.getElementById('lightCircle');
            if (lightCircle) { lightCircle.style.visibility = 'visible'; }
            setMission(true)
        };
        // L3 DOOR 
        if (mission && position.y === 0 && position.x === -300 ){
            setMission(false)
            const door = document.getElementById('affordances');
            if (door) { door.style.visibility = ''; }
            setLevel(4);
            setBackground(
                <Level_4 id="level" height="1080px" className="centered" />
            );
            setMaxX(400);
            setMaxY(0);
            setMinX(-370);
            setMinY(0);
            setTextEventData(
                lang === "en"
                    ? "Your fresh manuscript can be found in the safe."
                    : "Váš čerstvý rukopis můžete najít v trezoru."
            )
            resetButtons();
        }
    } else if (level === 4) {
        // L4 GOAL 1
        if (!mission && !mission2 &&  position.y === 0 && position.x === 260 ) {
            setTextEventData(
                lang === "en"
                    ? "Now all you have to do is send it in the elevator to the wrapping room."
                    : "Teď už jen zbývá ho poslat výtahem do balírny k zabalení."
            )
            // hide a css object with id #eventCircle
            const eventCircle = document.getElementById('eventCircle');
            if (eventCircle) { eventCircle.style.display = 'none'; }

            const book = document.getElementById('book');
            if (book) { book.style.visibility = 'visible'; }

            const trezorDoor = document.getElementById('trezorDoor');
            if (trezorDoor) { trezorDoor.style.visibility = 'visible'; }

            // show eventCircle2
            const eventCircle2 = document.getElementById('eventCircle2');
            if (eventCircle2) { eventCircle2.style.visibility = 'visible'; }
            setMission(true)
        };
        // L4 GOAL 2
        if (mission && !mission2 &&  position.y === 0 && position.x === -150 ) {
            setTextEventData(
                lang === "en"
                    ? "Follow the stairs down, please."
                    : "Po schodech dolů, prosím."
                )
            // hide a css object with id #eventCircle2
            const eventCircle2 = document.getElementById('eventCircle2');
            if (eventCircle2) { eventCircle2.style.visibility = 'hidden'; }
            // open door 
            const door = document.getElementById('affordances');
            if (door) { door.style.visibility = 'visible'; }

            const book = document.getElementById('book');
            if (book) { book.style.visibility = 'hidden'; }

            const light = document.getElementById('ligt');
            if (light) { light.style.visibility = 'visible'; }
            setMission2(true)
        };
        // L4 DOOR 
        if (mission && mission2 && position.y === 0 && position.x === 380 ){
            setMission(false)
            setMission2(false)
            const door = document.getElementById('affordances');
            if (door) { door.style.visibility = ''; }
            setLevel(5);
            setBackground(
                <Level_5 id="level" height="1080px" className="centered" />
            );
            setMaxX(400);
            setMaxY(-340);
            setMinX(-370);
            setMinY(-340);
            setPosition({ x: 400, y: -340 });
            setTextEventData(
                lang === "en"
                    ? "Take your wrapped book out of the elevator."
                    : "Vyjměte Vaší zabalenou knihu z výtahu.")
            resetButtons();
        }
    } else if (level === 5) {
        // L5 GOAL 
        if (position.y === -340 && position.x === -80) {
            setTextEventData(
                lang === "en"
                    ? "Now all you have to do is place it in its place in the warehouse."
                    : "Teď je jen potřeba umístit ji na své místo do skladu."
            )

            const book = document.getElementById('book');
            if (book) { book.style.visibility = 'visible'; }

            // hide a css object with id #eventCircle
            const eventCircle = document.getElementById('eventCircle');
            if (eventCircle) { eventCircle.style.display = 'none'; }
            // open door 
            const door = document.getElementById('affordances');
            if (door) { door.style.visibility = 'visible'; }
            setMission(true)
        };
        // L5 DOOR 
        if (mission && position.y === -340 && position.x === 180 ){
            setMission(false)
            const door = document.getElementById('affordances');
            if (door) { door.style.visibility = ''; }
            setLevel(6);
            setBackground(
                <Level_6 id="level" height="1080px" className="centered" />
            );
            setMaxX(670);
            setMaxY(-340);
            setMinX(50);
            setMinY(-340);
            setTextEventData(
                lang === "en"
                    ? "Place the book in its place on the shelves. We ordered these modular shelves all the way from Strasbourg."
                    : "Uložte knihu na jejím místě v regálech. Tyto modulární regály jsme objednávali až ze Štrasburku."
            )
            resetButtons();
        }
    } else if (level === 6) {  
        // L6 GOAL 
        if (position.y === -340 && position.x === 570 && !showGameOptions) {
            if (bookReview === "") {
                setTextEventData(
                    lang === "en"
                        ? "Done! We are just waiting for the first review."
                        : "Hotovo! Už jen čekáme na první recenzi."
                )
            } else {
                setTextEventData(
                    lang === "en"
                        ? "Done! We have received the first review: " + bookReview
                        : "Hotovo! Obdrželi jsme první recenzi: " + bookReview
                    )
            }
            
            const book = document.getElementById('book');
            if (book) { book.style.visibility = 'hidden'; }
            // hide a css object with id #eventCircle
            const eventCircle = document.getElementById('eventCircle');
            if (eventCircle) { eventCircle.style.display = 'none'; }
            const kniha = document.getElementById('kniha');
            if (kniha) { kniha.style.visibility = 'visible'; }
            // open door 
            const door = document.getElementById('affordances');
            if (door) { door.style.visibility = 'visible'; }
            setMission(true)
        };
        // L6 DOOR
        if (mission && position.y === -340 && position.x === 180 ){
            // for refresh page
            setTextEventData(
                lang === "en"
                    ? "Thank you for your visit!"
                    : "Děkujeme za prohlídku!"
                )
            setShowGameOptions(true);
        }
    };
  }, [position, level, textEventData, background, maxX, maxY, minX, minY, mission]);

  return null;
};

export default LevelLogic;