import React, { useEffect, useState } from 'react';

function TouchControls({ setPosition, setDirection, position, setLastDirectionChange }) {
  const [initialTouch, setInitialTouch] = useState({ x: 0, y: 0 });

  const handleTouchStart = (event) => {
    event.preventDefault();
    const touch = event.touches[0];
    setInitialTouch({ x: touch.clientX, y: touch.clientY });
  };

  const handleTouchEnd = (event) => {
    event.preventDefault();
  };

  const roundToNearestMultiple = (value, multiple) => {
    return Math.round(value / multiple) * multiple;
  };

  useEffect(() => {
    const handleTouchMove = (event) => {
      event.preventDefault();
      const touch = event.touches[0];
      const { clientX, clientY } = touch;
      const x = clientX - initialTouch.x;
      const y = clientY - initialTouch.y;
    
      // Scaling factor to control movement speed
      const scaleFactor = 2;
    
      // Calculate the distance of the touch movement
      const distance = Math.sqrt(x * x + y * y);
    
      // Dynamic speed adjustment based on the distance
      const adjustedSpeed = Math.min(distance, 10) * scaleFactor;
    
      // Calculate the new position with the adjusted speed
      const newPositionX = position.x + x * adjustedSpeed / distance;
      const newPositionY = position.y + y * adjustedSpeed / distance;
    
      const roundedX = roundToNearestMultiple(newPositionX, 10);
      const roundedY = roundToNearestMultiple(newPositionY, 10);

      // Update the position with the rounded values
      setPosition((prevPosition) => ({ ...prevPosition, x: roundedX }));
      setPosition((prevPosition) => ({ ...prevPosition, y: roundedY }));
      setLastDirectionChange(Date.now());

      // Calculate the direction based on the touch movement
      if (Math.abs(x) > Math.abs(y)) {
        if (x > 0) {
          setDirection(37); // Right
        } else {
          setDirection(39); // Left
        }
      } else {
        if (y > 0) {
          setDirection(38); // Down
        } else {
          setDirection(40); // Up
        }
      }
    };

    const touchContainer = document.getElementById('touch-container');
    touchContainer.addEventListener('touchstart', handleTouchStart, { passive: false });
    touchContainer.addEventListener('touchend', handleTouchEnd, { passive: false });
    touchContainer.addEventListener('touchmove', handleTouchMove, { passive: false });

    return () => {
      touchContainer.removeEventListener('touchstart', handleTouchStart);
      touchContainer.removeEventListener('touchend', handleTouchEnd);
      touchContainer.removeEventListener('touchmove', handleTouchMove);
    }
  }, [position, setPosition, setDirection, initialTouch, setLastDirectionChange]);

  return (
    <div
      id="touch-container"
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        width: '90vw',
        height: '90vh',
        zIndex: 10,
        transform: 'translate(-50%, -50%)',
      }}
    />
  );
}

export default TouchControls;