import { useEffect } from 'react';

function usePreloadImages(images) {
  useEffect(() => {
    const preloadImages = images.map((image) => {
      const img = new Image();
      img.src = image;
      return img;
    });

    return () => {
      preloadImages.forEach((img) => {
        img.src = '';
      });
    };
  }, [images]);
}

export default usePreloadImages;