import React, { useState, useRef, useEffect } from 'react';
import TouchControls from './TouchControls';

function MovementMechanics(props) {
  const [keysPressed, setKeysPressed] = useState({});
  const [lastDirectionChange, setLastDirectionChange] = useState(Date.now());

  const animationRef = useRef(null);

  const handleKeyDown = (event) => {
    setKeysPressed((prevKeysPressed) => ({ ...prevKeysPressed, [event.key]: true }));
  };

  const handleKeyUp = (event) => {
    setKeysPressed((prevKeysPressed) => {
      const newKeysPressed = { ...prevKeysPressed };
      delete newKeysPressed[event.key];
      return newKeysPressed;
    });
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  useEffect(() => {
    const element = document.getElementById('level');
    
    const applyTransition = () => {
      element.style.transition = 'transform 0.5s ease-out';
    };
  
    const timeoutId = setTimeout(applyTransition, 500);
  
    return () => clearTimeout(timeoutId);
  }, [props.background]);

  useEffect(() => {
    const element = document.getElementById('level');
    const animate = () => {
      animationRef.current = requestAnimationFrame(animate);

      let x = props.position.x;
      let y = props.position.y;
      let direction;

      if (keysPressed['ArrowRight'] && (props.position.x !== props.minX)) {
        x -= 5;
        direction = 39;
      }
      if (keysPressed['ArrowLeft'] && (props.position.x !== props.maxX)) {
        x += 5;
        direction = 37;
      }
      if (keysPressed['ArrowUp'] && (props.position.y !== props.maxY)) {
        y += 5;
        direction = 38;
      } else if (keysPressed['ArrowUp'] && (props.position.y === props.maxY)) {
        direction = 1;
      }
      if (keysPressed['ArrowDown'] && (props.position.y !== props.minY)) {
        y -= 5;
        direction = 40;
      }

      x = Math.min(Math.max(x, props.minX), props.maxX);
      y = Math.min(Math.max(y, props.minY), props.maxY);
      
      props.setPosition({ x, y });
      if (direction) {
        props.setDirection(direction);
        setLastDirectionChange(Date.now());
      }
      element.style.transform = `translate(${x}px, ${y}px)`;
    };

    animationRef.current = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(animationRef.current);
  }, [keysPressed, props]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (Date.now() - lastDirectionChange >= 500) {
        props.setDirection(0);
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [lastDirectionChange, props.setDirection]);

  return (
    <TouchControls
      setPosition={props.setPosition}
      setDirection={props.setDirection}
      setLastDirectionChange={setLastDirectionChange}
      position={props.position}
    />
  );
}

export default MovementMechanics;