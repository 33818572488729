import React, { useEffect, useRef } from 'react';
import '../App.css';
import BookInput from './BookInput';
import GameOptions from './GameOptions';

function TextEvent(props) {
  const { text, level, mission, bookTitle, setBookTitle, bookReview, setBookReview, lang, showGameOptions} = props;
  const textRef = useRef(null);

  useEffect(() => {
    let index = 0;
    let interval;

    if (textRef.current) {
      textRef.current.textContent = ''; // Clear the existing text
    }

    const timeout = setTimeout(() => {
      interval = setInterval(() => {
        if (index < text.length) {
          if (textRef.current) {
            textRef.current.textContent += text[index];
          }
          index++;
        } else {
          clearInterval(interval);
        }
      }, 50); // Change this value to adjust the typing speed
    }, 100); // Change this value to adjust the initial delay before typing starts

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [text]);

  return (
    <div className="text-center">
      <p className="typewriter" ref={textRef}></p>
      {level === 3 && mission && <p><b>{bookTitle}</b></p>}
      {level === 3 && mission && bookTitle === '' && 
        <BookInput
          bookTitle={bookTitle}
          setBookTitle={setBookTitle}
          bookReview={bookReview}
          setBookReview={setBookReview}
          lang={lang}
        />
      }
      {level === 6 && mission && showGameOptions &&
        <GameOptions
          bookTitle={bookTitle}
          bookReview={bookReview}
          lang={lang}
        />
      }
    </div>
  );
}

export default TextEvent;