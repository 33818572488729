import React from 'react';

function Book() {
  return (
    <div
      id="book"
      style={{
        backgroundColor: 'black',
        width: '13px',
        height: '20px',
        zIndex: 10,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-55%, -50%)',
        zIndex: 5,
        visibility: 'hidden',
      }}
    />
  );
}

export default Book;