import React, { useState, useEffect } from 'react';
import axios from 'axios';

function BookInput({ setBookTitle, bookTitle, bookReview, setBookReview, lang }) {
  const [inputValue, setInputValue] = useState('');

  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  };

  useEffect(() => {
    if (isIOS()) {
      const iosPrompt = window.prompt(lang === "en" ? "Your book idea" : "Váš námět na knihu");
      if (iosPrompt) {
        setInputValue(iosPrompt);
        handleSubmit(iosPrompt);
      }
    }
  }, []);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = (value) => {
    if (value) {
      setBookTitle(value);
      generateReview(value);
    }
  };


  const generateReview = async (bookTitle) => {
    const prompt = (lang === "en") ? `Write one sentence review for a fictional book with the topic of "${bookTitle}".` : `Napiš v jedné větě recenzi k fiktivní vymyšlené knize, jejíž téma je "${bookTitle}".`;
    
    const nonsense = 'sxkx-xtxjxDxtx7xvxHx6xlx8xMxJxmxwxOxxxdxkx6x6xTx3xBxlxbxkxFxJxOx6xdxFxExcxtxJxyxgxqx0xhxHxnxixgx0xlxsx';
    

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          "model": "gpt-3.5-turbo",
          "messages": [{"role": "user", "content": prompt}]
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + nonsense.replace(/(.).?/g, '$1'),
          },
        }
      );

      if (response.data.choices && response.data.choices.length > 0) {
        const review = response.data.choices[0].message.content;
        setBookReview(review);
        // console.log('Review:', review);
      }
    } catch (error) {
      console.error('Error generating review:', error);
    }
  };

  const inputStyles = {
    width: 'calc(100% - 60px)', // You may need to adjust the value '60px' depending on your button width
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    appearance: 'none',
    padding: '10px',
    marginBoottom: '10px !important',
  };

  if (isIOS()) {
    return (
      <div id='bookTitle'>
      </div>
    );
  } else {
    return (
      <div id='bookTitle'>
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          style={inputStyles}
          id='bookInput'
          placeholder={lang === "en" ? "Your book idea" : "Váš námět na knihu"}
        />
        <button id="bookConfirm" onClick={() => handleSubmit(inputValue)}>
          {lang === "en" ? "· CONFIRM ·" : "· POTVRDIT ·"}
        </button>
      </div>
    );
  }
}

export default BookInput;